import React from 'react';
import type { BaseData } from '../../utils';
import { NormalProgrammeParams, RenderByPermission, request, MainSubStructureModel } from '../../utils';
import { observable } from 'mobx';
import { Button, Modal, message, Tag } from 'antd';
import RuleEditorStore from './modal/ruleEditorModel';
import { RuleEnable } from './modal/ruleEnable';
import RuleEnableModel from './modal/ruleEnableModel';
import { API } from './api';
import { UserTypeDict } from '../../utils/dict';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  public ruleEditorStore = new RuleEditorStore({ parent: this });

  public ruleEnableStore = new RuleEnableModel({ parent: this });

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'ruleId',
          name: '操作',
          width: 150,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type="link"
                    onClick={() => this.ruleEditorStore.onShow(row)}
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_12`}>
                  <RuleEnable
                    row={row}
                    store={this.ruleEnableStore}
                  />
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'businessName',
          name: '业务名称',
          formatter: () => '社群订单奖励业务',
        },
        {
          key: 'divisionMethod',
          name: '分成方式',
          formatter: () => '比例',
        },
        {
          key: 'userType',
          name: '角色',
          formatter: ({ row }) => {
            const userTypeMap = {
              1: '普通用户',
              2: '分销商',
            };
            return UserTypeDict.find((item) => item.dictValue === row.userType)?.dictName || '';
          },
        },
        {
          key: 'proportion',
          name: '比例',
          formatter: ({ row }) => `${row.proportion || 0}%`,
        },
        {
          key: 'day',
          name: '拉新保护期',
          formatter: ({ row }) => `${row.day || 0}天`,
        },
        {
          key: 'isDisable',
          name: '状态',
          formatter: ({ row }) => {
            const statusMap = {
              0: '已启用',
              1: '已停用',
            };
            return <Tag color={row.isDisable ? 'red' : '#87d068'}>{statusMap[row.isDisable] || ''}</Tag>;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      showRefresh: true,
      primaryKeyField: 'userType',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: true,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<BaseData<any>>({
          url: API.queryInviteConfig,
          method: 'GET',
        });
      },
    },
  });
}
