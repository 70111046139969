import { observer } from 'mobx-react';
import type Model from './ruleEnableModel';
import { Button, Popconfirm } from 'antd';
import React from 'react';

export const RuleEnable = observer(({ store, row }: { store: Model; row: any }) => {
  const { onRuleEnable } = store;

  const statusMap = {
    1: '启用',
    0: '停用',
  };

  const text = statusMap[row.isDisable] || '停用';

  return (
    <Popconfirm
      title="提示"
      description={`是否${text}该邀请规则?`}
      onConfirm={() => onRuleEnable(row)}
    >
      <Button type="link">{text}</Button>
    </Popconfirm>
  );
});
