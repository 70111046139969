import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { action, observable } from 'mobx';
import { API } from '../api';

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

export default class DemandEditorModel {
  @observable
  public visible = false;

  @observable
  public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public userType: number;

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (data?: any) => {
    this.userType = data?.userType;
    this.visible = true;
    setTimeout(() => {
      this.ref.setFieldsValue(data);
    }, 0);
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    const postParams = { ...values };
    request<resultValue<any>>({
      url: API.editInviteConfig,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onClose();
        this.parent.mainSubStructureModel?.gridModel?.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
