import { SearchListStructure } from '../../utils/searchListStructure';
import { MainSubStructure } from '../../utils/egGrid';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Model from './model';
import RuleEditorModal from './modal/ruleEditor';
import styles from './index.less';

const DemandConfig = () => {
  const store = useMemo(() => new Model(), []);
  const { mainSubStructureModel, ruleEditorStore } = store;

  return (
    <div className={styles.page}>
      <MainSubStructure store={mainSubStructureModel} />
      <RuleEditorModal store={ruleEditorStore} />
    </div>
  );
};
export default observer(DemandConfig);
