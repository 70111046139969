import { action } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { API } from '../api';
import { message } from 'antd';

export default class RuleEnableModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  private parent: any;

  @action
  public onRuleEnable = (row: any) => {
    const _row = {
      ...row,
      isDisable: row.isDisable === 1 ? 0 : 1,
    };
    request<BaseData<any>>({
      url: API.editInviteConfig,
      method: 'POST',
      data: _row,
    })
      .then((res) => {
        this.parent?.mainSubStructureModel?.gridModel?.onQuery();
        message.success('操作成功！');
      })
      .catch((err) => Promise.reject(err));
  };
}
