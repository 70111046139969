import { Button, Form, Input, Modal, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './ruleEditorModel';
import { UserTypeDict } from '../../../utils/dict';

// 新增或编辑邀请规则
export default observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { visible, saveLoading, userType, onClose, onSave, setRef } = store;
  return (
    <Modal
      centered
      onOk={onSave}
      confirmLoading={saveLoading}
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      okText="确定修改"
    >
      <div style={{ paddingTop: '30px' }}>
        <Form ref={setRef}>
          <Form.Item
            label="业务名称"
            name="businessName"
          >
            <span>社群订单奖励业务</span>
          </Form.Item>
          <Form.Item
            label="分成方式"
            name="divisionMethod"
          >
            <span>比例</span>
          </Form.Item>
          <Form.Item
            label="适用角色"
            name="userType"
          >
            <span>{UserTypeDict.find((item) => item.dictValue === userType)?.dictName}</span>
          </Form.Item>
          <Form.Item
            label="奖励比例"
            name="proportion"
            rules={[
              {
                required: true,
                message: '请输入奖励比例',
              },
              {
                validator: (rule, value) => {
                  if (value || value === 0) {
                    if (!/^\d+$/.test(value)) {
                      return Promise.reject('请输入整数');
                    }
                    if (value < 0 || value > 100) {
                      return Promise.reject('请输入0-100之间的整数');
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              addonAfter="%"
              placeholder="请输入整数"
              maxLength={3}
            />
          </Form.Item>
          <Form.Item
            label="拉新保护期"
            name="day"
            rules={[
              {
                required: true,
                message: '请输入奖励比例',
              },
              {
                pattern: /^\d+$/,
                message: '请输入整数',
              },
            ]}
          >
            <Input
              addonAfter="天"
              placeholder="请输入整数"
              maxLength={6}
            />
          </Form.Item>
          <Form.Item
            label="是否禁用"
            name="isDisable"
            style={{ display: 'none' }}
          />
        </Form>
        <div
          style={{
            color: 'red',
            textAlign: 'center',
          }}
        >
          编辑后的规则无法应用到历史数据，请知悉
        </div>
      </div>
    </Modal>
  );
});
